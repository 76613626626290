exports.components = {
  "component---src-pages-actualities-js": () => import("./../../../src/pages/actualities.js" /* webpackChunkName: "component---src-pages-actualities-js" */),
  "component---src-pages-history-js": () => import("./../../../src/pages/history.js" /* webpackChunkName: "component---src-pages-history-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-staff-js": () => import("./../../../src/pages/staff.js" /* webpackChunkName: "component---src-pages-staff-js" */),
  "component---src-pages-students-js": () => import("./../../../src/pages/students.js" /* webpackChunkName: "component---src-pages-students-js" */),
  "component---src-pages-teaching-js": () => import("./../../../src/pages/teaching.js" /* webpackChunkName: "component---src-pages-teaching-js" */),
  "component---src-templates-person-detail-js": () => import("./../../../src/templates/personDetail.js" /* webpackChunkName: "component---src-templates-person-detail-js" */),
  "component---src-templates-project-detail-js": () => import("./../../../src/templates/projectDetail.js" /* webpackChunkName: "component---src-templates-project-detail-js" */)
}

